import req from "../methods/req"
import method from '../methods/method'

// 获取客户列表
export const outbound_order_index = function(data) {
	return method.get_list({
		url: '/manage/crm/outbound_order_index',
		...data
	})
}

export const distributor_customer_list = function(data) {

	return method.get_list({
		url: '/manage/crm/distributor_customer_list',
		...data
	})
}

export const setting_detail = function(data) {
	return req({
		url: '/manage/crm/setting_detail',
		...data
	})
}


export const setting_index = function(data) {
	return req({
		url: '/manage/crm/setting_index',
		...data
	})
}

export const outbound_order_detail = function(data) {
	return req({
		url: '/manage/crm/outbound_order_detail',
		...data
	})
}


export const outbound_order_push = function(data) {
	return req({
		url: '/manage/crm/outbound_order_push',
		...data
	})
}



export const setting_del = function(data) {
	return req({
		url: '/manage/crm/setting_del',
		...data
	})
}

//经销分销组合字段下拉选项
export const setting_select = function(data) {
	return req({
		url: '/manage/crm/setting_get_select',
		...data
	})
}

//经销分销保存组合字段
export const setting_save_field = function(data) {
	return req({
		url: '/manage/crm/setting_save_field',
		...data
	})
}

//经销分销保存签名状态
export const setting_save_status = function(data) {
	return req({
		url: '/manage/crm/setting_save_status',
		...data
	})
}

//经销分销导出
export const outbound_order_export = function(data) {
	return req({
		url: '/manage/crm.outbound_order/export',
		...data
	})
}

//仓库管理列表
export const get_warehouse_list = function(data) {
	return method.get_list({
		url: '/manage/crm.ware_house/getWareList',
		...data
	})
}

//仓库详情
export const get_ware_detail = function(data) {
	return req({
		url: '/manage/crm.ware_house/geteWareDetail',
		...data
	})
}

//承运商下拉
export const get_carrier_select = function(data) {
	return req({
		url: '/manage/crm.ware_house/getCarrierSelect',
		...data
	})
}

//绑定承运商
export const get_bind_carrier = function(data) {
	return req({
		url: '/manage/crm.ware_house/wareBindCarrier',
		...data
	})
}
//删除承运商绑定
export const del_warehouse_bind = function(data) {
	return req({
		url: '/manage/crm.ware_house/delWarehouseBind',
		...data
	})
}
//绑定仓库管理
export const save_ware_manage = function(data) {
	return req({
		url: '/manage/crm.ware_house/saveWarehouseManage',
		...data
	})
}
//删除承运商绑定
export const del_ware_manage = function(data) {
	return req({
		url: '/manage/crm.ware_house/delWarehouseManage',
		...data
	})
}
//仓库产品列表
export const get_ware_product = function(data) {
	return method.get_list({
		url: '/manage/crm.ware_house/getWareProductList',
		...data
	})
}
//保存仓库商品
export const save_ware_product = function(data) {
	return req({
		url: '/manage/crm.ware_house/saveWareProduct',
		...data
	})
}
//删除仓库商品
export const del_ware_product = function(data) {
	return req({
		url: '/manage/crm.ware_house/delWareProduct',
		...data
	})
}

//同步仓库
export const push_ware = function(data) {
	return req({
		url: '/manage/crm.ware_house/pushWarehouse',
		...data
	})
}

//同步承运商
export const push_carrier = function(data) {
	return req({
		url: '/manage/crm.ware_house/pushCarrier',
		...data
	})
}

//司机管理列表
export const get_driver_list = function(data) {
	return method.get_list({
		url: '/manage/crm.driver/getDriverList',
		...data
	})
}
//司机详情
export const get_driver_detail = function(data) {
	return req({
		url: '/manage/crm.driver/getDriverDetail',
		...data
	})
}
//保存司机信息
export const save_driver = function(data) {
	return req({
		url: '/manage/crm.driver/saveDriver',
		...data
	})
}

//配送单列表
export const get_send_list = function(data) {
	return method.get_list({
		url: '/manage/crm.electronic/getSendList',
		...data
	})
}
//出库单明细
export const get_send_shipment = function(data) {
	return req({
		url: '/manage/crm.electronic/getSendShipment',
		...data
	})
}
//配送单详情
export const get_send_detail = function(data) {
	return req({
		url: '/manage/crm.electronic/getSendDetail',
		...data
	})
}
//空桶订单列表
export const get_empty_barrel = function(data) {
	return method.get_list({
		url: '/manage/crm.electronic/getEmptyBarrelList',
		...data
	})
}
//空桶订单经销商信息
export const get_seller_empty = function(data) {
	return req({
		url: '/manage/crm.electronic/getSellerEmptyBarrel',
		...data
	})
}
//空桶订单详情
export const get_empty_detail = function(data) {
	return req({
		url: '/manage/crm.electronic/getEmptyBarrelDetail',
		...data
	})
}
//电子签收配置详情
export const get_template_setting = function(data) {
	return req({
		url: '/manage/crm.electronic/getTemplateSetting',
		...data
	})
}
//保存电子签收配置
export const save_template_setting = function(data) {
	return req({
		url: '/manage/crm.electronic/saveTemplateSetting',
		...data
	})
}
//保存电子签收产品配置
export const save_product_rule = function(data) {
	return req({
		url: '/manage/crm.electronic/saveProductRule',
		...data
	})
}
//保存电子签收产品配置
export const save_config = function(data) {
	return req({
		url: '/manage/crm.electronic/saveConfig',
		...data
	})
}

//获取未绑定客户列表
export const customer_seller_list = function(data) {

	return method.get_list({
		url: '/manage/crm.electronic/getCustomerList',
		...data
	})
}

//获取空桶商品列表
export const getEmptyGoodsList = function(data) {

	return method.get_list({
		url: '/manage/crm.electronic/getEmptyGoodsList',
		...data
	})
}
//保存空桶商品
export const changeGoods = function(data) {
	return req({
		url: '/manage/crm.electronic/changeGoods',
		...data
	})
}
//获取商品下拉
export const getGoodsSelect = function(data) {
	return req({
		url: '/manage/crm.ware_house/getGoodsSelect',
		...data
	})
}
//商品删除
export const goodsDelete = function(data) {
	return req({
		url: '/manage/crm.electronic/goodsDelete',
		...data
	})
}
//获取状态下拉
export const getStatusSelect = function(data) {
	return req({
		url: '/manage/crm.electronic/getStatusSelect',
		...data
	})
}
//修改配送单状态
export const changeSendOrderStatus = function(data) {
	return req({
		url: '/manage/crm.electronic/changeSendOrderStatus',
		...data
	})
}
//修改配出库单状态
export const changeOutcomeStatus = function(data) {
	return req({
		url: '/manage/crm.electronic/changeOutcomeStatus',
		...data
	})
}

//仓库下拉
export const getWareSelect = function(data) {

	return method.get_list({
		url: '/manage/crm.common/getWareSelect',
		...data
	})
}
//司机下拉
export const getDriverSelect = function(data) {

	return method.get_list({
		url: '/manage/crm.common/getDriverSelect',
		...data
	})
}
//保存空桶信息
export const saveEmptyBarrel = function(data) {
	return req({
		url: '/manage/crm.electronic/saveEmptyBarrel',
		...data
	})
}
//修改空桶状态
export const changeEmptyStatus = function(data) {
	return req({
		url: '/manage/crm.electronic/changeEmptyStatus',
		...data
	})
}

//发送消息
export const pushTemplateNotice = function(data) {
	return req({
		url: '/manage/crm.electronic/pushTemplateNotice',
		...data
	})
}

//修改电话车牌
export const change_driver = function(data) {
	return req({
		url: '/manage/crm.electronic/changeDriverInfo',
		...data
	})
}
//出库单导出
export const export_send_order = function(data) {
	return req({
		url: '/manage/crm.electronic/exportSendOrder',
		...data
	})
}
//订货单列表
export const get_order_list = function(data) {
	return method.get_list({
		url: '/manage/crm.electronic/getOrderList',
		...data
	})
}
//订货单详情
export const get_order_detail = function(data) {
	return req({
		url: '/manage/crm.electronic/getOrderDetail',
		...data
	})
}

//获取寄存列表
export const get_deposit_list = function(data) {
	return method.get_list({
		url: '/manage/crm.deposit/getDepositList',
		...data
	})
}

//寄存订单详情
export const get_deposit_detail = function(data) {
	return req({
		url: '/manage/crm.deposit/getDepositDetail',
		...data
	})
}

//寄存下拉
export const get_deposit_select = function(data) {
	return req({
		url: '/manage/crm.deposit/getDepositStatusSelect',
		...data
	})
}

//寄存导出
export const import_deposit = function(data) {
	return req({
		url: '/manage/crm.deposit/importDeposit',
		...data
	})
}

//获取还托盘列表
export const get_tray_list = function(data) {
	return method.get_list({
		url: '/manage/crm.tray/getTrayList',
		...data
	})
}

//还托盘详情
export const get_tray_detail = function(data) {
	return req({
		url: '/manage/crm.tray/getTrayDetail',
		...data
	})
}

//获取状态下拉
export const get_tray_select = function(data) {
	return req({
		url: '/manage/crm.tray/getTraySelect',
		...data
	})
}

//获取还托盘订单列表
export const get_tray_order_list = function(data) {
	return method.get_list({
		url: '/manage/crm.tray/getTrayOrderList',
		...data
	})
}

//还托盘订单详情
export const get_tray_order_detail = function(data) {
	return req({
		url: '/manage/crm.tray/getTrayOrderDetail',
		...data
	})
}

//还托盘订单经销商
export const get_tray_order_seller = function(data) {
	return req({
		url: '/manage/crm.tray/getTrayOrderSeller',
		...data
	})
}

//获取经销商列表
export const get_seller_list = function(data) {
	return method.get_list({
		url: '/manage/crm.seller/getSellerList',
		...data
	})
}

//经销商详情
export const get_seller_detail = function(data) {
	return req({
		url: '/manage/crm.seller/getSellerDetail',
		...data
	})
}

//经销商类型
export const get_seller_select = function(data) {
	return req({
		url: '/manage/crm.seller/getSellerSelect',
		...data
	})
}

//保存经销商信息
export const save_seller = function(data) {
	return req({
		url: '/manage/crm.seller/saveSeller',
		...data
	})
}

//同步经（分）销商仓库
export const pushSellerStorage = function(data) {
	return req({
		url: '/manage/crm.ware_house/pushSellerStorage',
		...data
	})
}

//同步经（分）销商托盘
export const pushTrayInfo = function(data) {
	return req({
		url: '/manage/crm.tray/pushTrayInfo',
		...data
	})
}

//保存托盘损坏信息接收人
export const saveTraySend = function(data) {
	return req({
		url: '/manage/crm.electronic/saveTraySend',
		...data
	})
}

// 溯源码权限配置
export const setTracingAuth = function(data) {
	return req({
		url: '/manage/crm.electronic/setTracingAuth',
		...data
	})
}

// 解绑
export const driverUnbind = function(data) {
	return req({
		url: '/manage/crm.driver/driverUnbind',
		...data
	})
}

// 选择工厂
export const changeFactory = function(data) {
	return req({
		url: '/manage/crm.ware_house/changeFactory',
		...data
	})
}

// 修改经销商信息
export const changeSellerBind = function(data) {
	return req({
		url: '/manage/crm.seller/changeSellerBind',
		...data
	})
}

// 删除工厂
export const delFactory = function(data) {
	return req({
		url: '/manage/crm.ware_house/delFactory',
		...data
	})
}

// 保存经销商司机信息
export const saveSellerDriver = function(data) {
	return req({
		url: '/manage/crm.seller/saveSellerDriver',
		...data
	})
}


export const getDriverTruckList = function(data) {
	return method.get_list({
		url: '/manage/crm.driver/getDriverTruckList',
		...data
	})
}

// 删除车牌
export const delSellerDriver = function(data) {
	return req({
		url: '/manage/crm.seller/delSellerDriver',
		...data
	})
}


// 设置批量带板
export const changeWarehouseWithPlate = function(data) {
	return req({
		
		url: '/manage/crm.ware_house/changeWarehouseWithPlate',
		...data
	})
}