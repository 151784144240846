<template>
    <div>
        <TableList :columns="columns" :get_table_list="this.get_table_list" :form_data_seo="form_data_seo"
            :slot_table_list="['operation']" ref="list" :rowKey="'id'" >
            <template slot="right_btn" v-auth="'change'">
                <export-table v-if="this.$store.state.page_auth.export" url="/manage/crm.tray/exportTrayOrder">
				</export-table>
                <!-- <a-button class="float_left" v-auth="'change'" @click="download()" type="primary">导出</a-button> -->
            </template>
            <template slot="operation" slot-scope="data">
                <a @click="go_detail(data.record)">查看</a>
            </template>
        </TableList>
    </div>
</template>
<script>
    // @ is an alias to /src
    import TableList from "@/components/TableList";
    import {
        get_tray_order_list,
        get_tray_select
    } from '@/api/push'
    const columns = [{
            title: "司机",
            dataIndex: "chauffeur",
        },
        {
            title: "司机电话",
            dataIndex: "chauffeur_mobile",
        },
        {
            title: "车牌",
            dataIndex: "truck_license",
        },
        {
            title: "仓管",
            dataIndex: "username",
        },
        {
            title: "仓库",
            dataIndex: "storage_name",
        },
        {
            title: "数量",
            dataIndex: "total_num",
        },
        {
            title: "状态",
            dataIndex: "status_name",
        },
        {
            title: "归还时间",
            dataIndex: "create_time",
        },
        {
            title: "入库时间",
            dataIndex: "finish_time",
        },
        {
            title: "操作",
            scopedSlots: {
                customRender: "operation"
            }
        }
    ];


    export default {
        name: "Index",
        components: {
            TableList,
        },
        data() {
            return {
                get_table_list: get_tray_order_list,
                columns,
                visible: false,
                form_data_seo: {
                    ...this.$config.form_data_seo,
                    list: [

                        {
                            type: "text",
                            name: "keyword",
                            title: "关键词",
                            placeholder: "请输入关键词",
                            options: {}
                        },
                        {
                            type: "range_date",
                            name: "range_date",
                            title: "归还时间",
                            options: {},
                            start: {
                                name: 'start_time'
                            },
                            end: {
                                name: 'end_time'
                            },
                        },
                        {
                            type: "range_date",
                            name: "range_end_date",
                            title: "入库时间",
                            options: {},
                            start: {
                                name: 'start_back_time'
                            },
                            end: {
                                name: 'end_back_time'
                            },
                        },
                        {
                            type: "select",
                            name: "status",
                            list: [],
                            title: "状态",
                            options: {}
                        },
                    ],
                },
            };
        },
        created() {
            this.get_select()
        },
        methods: {
            //下拉
            get_select() {
                get_tray_select({
                    data:{
                        type:"order"
                    }
                }).then(res=>{
                    this.form_data_seo.list = this.$method.set_form_list(this.form_data_seo.list, 'status', 'list', res.data.list)
                })
            },
            //查看司机信息
            go_detail(e) {
                this.$keep_route.add(this.$options.name);
                this.$router.push({
                    path: '/tray_order/detail',
                    query: {
                        id: e.id
                    }
                })
            },
        }
    };
</script>
